import React, { Fragment, useCallback, useEffect, useState } from 'react';
import config from '../../../config';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import { useGlobalFuntction } from '../../../generalFunction/globalFunction';
import api from '../../../services/api';
import '../../../style/cetak-invoice.css'

const CetakInvoicePage = (props) => {
    const tagihanId = localStorage.getItem('tagihanId') || 0;
    const { isLoading, setIsLoading } = useGlobalFuntction();
    const [info, setInfo] = useState(null);

    const fetchData = useCallback(async(id=0) => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.tagihan}${id}/`).then(res => res.data);
            if (res){                
                setInfo(res.results);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            console.log('error')
        }
    },[setIsLoading])

    useEffect(() => {
        if (tagihanId){
            fetchData(tagihanId);
        }
    },[fetchData, tagihanId])
    return (
        <Fragment>
            { isLoading && <LoaderComponent/> }
            <div className="invoice-container">
                <div className="header">
                    <div className="logo">
                        <img src={require('../../../assets/invoice/LogoSimaya.png')} alt="logo"/>
                    </div>
                    <div className="alamat-header">
                        <div className="perusahaan-name">Simaya</div>
                        <div className="alamat">
                            <div>Jl. Mentor I no. 53 Bandung, ID, 40175</div>
                            <div>087823459455</div>
                            <div>finance@simaya.net.id</div>                        
                        </div>
                    </div>
                </div>
                <div className="body">
                    <div className="alamat-pelanggan">
                        <div>Kepada Yth:</div>
                        <div className='bold'>{info && info.konsumen_name}</div>
                        <div>{info && info.konsumen_display && info.konsumen_display.alamat}</div>
                        <div>{info && info.konsumen_display && `${info.konsumen_display.kabupaten_display} ${info.konsumen_display.provinsi_display}`}</div>
                        <div>{info && info.konsumen_display.email }</div>
                    </div>
                    <div className="noinvoice">
                        {info && info.no_tagihan}
                    </div>
                    <div className="periode-invoice">
                        { info && info.tipe_tagihan && info.tipe_tagihan === 2 &&
                            <div>Periode Akses : { info && info.periode_akses }</div>
                        }
                        
                        <div>Tanggal Tagihan : { info && info.periode_date_display }</div>
                        <div>Jatuh Tempo :  { info && info.due_date_display }</div>
                    </div>
                    <div className="perincian-invoice">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Layanan</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center blue'>1   </td>
                                    <td>{ info && info.produk_name } { info && info.keterangan ? `- ${info.keterangan}` : ''}</td>
                                    <td className='text-right blue'>Rp{ info && info.harga_display && info.harga_display.toLocaleString() }</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="2" className='text-right'>Grand Total</td>
                                    <td className="text-right ">Rp{ info && info.harga_display && info.harga_display.toLocaleString() }</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="catatan">
                        <div>Catatan :</div>
                        <div>- Apabila pelanggan belum melakukan pembayaran sampai dengan jatuh tempo, maka akan dilakukan pemutusan koneksi sementara terhitung mulai pukul 24.00 pada tanggal akhir jatuh tempo</div>
                    </div>
                    <div className="sponsor">
                        <div className='text-center'>Supported by :</div>
                        <div className="sponsor-icon">
                            <div className='cifo'><img src={require('../../../assets/invoice/cifo.png')} alt="logo cifo"/></div>
                            <div className='walanja'><img src={require('../../../assets/invoice/Walanja.png')} alt="logo Walanja"/></div>
                            <div className='citra'><img src={require('../../../assets/invoice/citranetl.png')} alt="logo citranetl"/></div>
                            <div className='simaya'><img src={require('../../../assets/invoice/LogoSimaya.png')} alt="logo LogoSimaya"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default CetakInvoicePage;