export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        // development: "https://workspacesimaya.myappscenter.com",
        // production: "https://workspacesimaya.myappscenter.com",
        development: "https://simayademo.myappscenter.com",
        production: "https://simayademo.myappscenter.com",
        // development: "http://127.0.0.1:8000",
        // production: "http://127.0.0.1:8000",
        // development: "http://192.168.1.6:8000",
        // production: "http://192.168.1.6:8000",
        // development: "http://172.30.2.10:8000",
        // production: "http://172.30.2.10:8000",
    },
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__gifiHomesUser",
      ACCESS_TOKEN: "__gifiHomesAccessToken",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm" 
    },
    cookiesName : 'gifihome',
    expiresSession : 86400,
    itemPerPage : 20,
    timeOutValue : 5000,
    initLocation : {
      lat : -6.892220050781338, 
      lng : 107.56976029847955
    },
    googleMapApiKey : 'AIzaSyDm751ucwcFMFw6dztSqEfgCKeYoz2hTYc',
    endPoint : {
      accountv1 : `/akunting/api/v1/master-akun/`,
      daftarVa : `/akunting/api/v1/daftar-va/`,
      createPaymentUrl : `/akunting/api/create-payment-url`,
      cancelPayment : `/akunting/api/cancel-transaction-midtrans/`,
      perusahaanv1 : `/master/api/v1/perusahaan/`,
      cabangv1 : `/master/api/v1/cabang/`,
      rekananv1 : `/master/api/v1/rekanan/`,
      satuanv1 : `/produk/api/v1/satuan/`,
      productionMode : `/master/api/v1/get-production-mode/`,

      groupPermissionv1 : `/auth/api/v1/permission-group/`,

      authv1 : `/auth/api/v1/`,
      bankv1 : `/akunting/api/v1/bank/`,
      billing : `/billing/api`,
      gantiStatusIsolirAktif : `/billing/api/v1/ganti-status-isolir-aktif/`,
      checkStatusUserPpoe : `/billing/api/v1/check-status-user-pppoe/`,
      isolirPelangganSingle : `/billing/api/v1/isolir-pelanggan-single/`,
      bukaIsolirPelangganSingle : `/billing/api/v1/buka-isolir-pelanggan-single/`,
      logAutoIsolirResume : `/billing/api/v1/auto-isolir-log/`,
      logAutoIsolirRouter : `/billing/api/v1/auto-isolir-router/`,
      logAutoIsolirDetail : `/billing/api/v1/auto-isolir-detail/`,

      changeProfileMikrotik : `/billing/api/v1/change-profile-mikrotik/`,
      billingFotov1 : `api/v1/billing-foto/`,
      billingv1 : `/api/v1/billing/`,
      billingChangeStatus : `/billing/api/change-status/`,
      billingGantiPerangkat : `/billing/api/v1/ganti-perangkat/`,
      billingMasterReferralv1 : `/billing/api/v1/master-referral/`,
      billingBySales : `/billing/api/v1/billing-bysales/`,
      billingStatusLog : `/billing/api/v1/billing-status-log/`,
      billingBySalesDetail : `/billing/api/v1/billing-bysales-detail/`,
      backupDatabase : `/master/api/backup-file-list/`,
      backupDatabaseExec : `/master/api/exec-backup/`,
      changePassword : `/auth/api/change-password`,
      dashboard : `/api/v1/dashboard/`,
      customerStatistic : `/dashboard/api/v1/customers-statistic/`,
      orderStatistic : `/dashboard/api/v1/orders-statistic/`,
      resumeAktivasi : `/dashboard/api/v1/resume-aktivasi/`,
      resumePendaftaran : `/dashboard/api/v1/resume-pendaftaran/`,
      resumeTerminates : `/dashboard/api/v1/resume-terminates/`,
      devices : `/master/api/devices/`,
      devicesShowPelanggan : `/master/api/devices-show-pelanggan/`,
      checkMikrotikStatus : `/master/api/v1/check-mikrotik-status/`,
      devicesProfile : `/master/api/devices-profile/`,
      gantiTeknis : `/billing/api/ganti-teknis/`,
      teknisOnDutyV1 : `/kontak/api/v1/teknis-onduty/`,
      pengembalianPerangkatTeknis : `/produk/api/pengembalian-produk-dari-teknis`,
      hitungGaji : `/hrd/api/hitung-gaji/`,
      hrd : `/hrd/api/`,
      initialSetup : `/master/api/initial-setup/`,
      ippool : `/master/api/ippool/`,
      kontak : `/kontak/api/kontak`,
      kontakv1 : `/kontak/api/v1/kontak/`,
      salesmanv1 : `/kontak/api/v1/salesman/`,
      jurnalGroup : '/akunting/api/v1/jurnal-group/',
      jurnalv1 : '/akunting/api/v1/jurnal/',
      configServer : `/master/api/v1/config-server/`,
      tagihan : `/tagihan/api/v1/tagihan/`,
      hapusTagihan : `/tagihan/api/v1/hapus-tagihan/`,
      penerimaanPembayaranTagihan : `/tagihan/api/v1/pembayaran-manual/`,

      // provinsi
      provinsiv1 : `/master/api/v1/provinsi/`,
      kabupatenv1 : `/master/api/v1/kabupaten/`,
      kecamatanv1 : `/master/api/v1/kecamatan/`,
      desav1 : `/master/api/v1/desa/`,

      // kategori
      kategori : '/produk/api/kategori',
      kategoriv1 : '/produk/api/v1/kategori/',

      kwitansi : `/akunting/api/kwitansi/`,
      kwitansiv1 : `/akunting/api/v1/kwitansi/`,
      exportInvoice : `/akunting/api/export-invoice/`,
      
      lokasi : `/produk/api/lokasi`,
      lokasiv1 : `/produk/api/v1/lokasi/`,
      master : `/master/api`,
      mikrotikFailure : `/billing/api/v1/mikrotik-failure/`,
      nearbyOdp : `/master/api/odp/nearby`,
      odpv1 : `/master/api/v1/lokasi-odp/`,
      pegawaiv1 : `/kontak/api/v1/pegawai/`,
      pegawaiOpt : `/kontak/api/get-pegawai-opt`,
      approvePembayaranReferal: `/akunting/api/approve-pembayaran-referral/`,
      pembayaranReferalv1: `/akunting/api/v1/pembayaran-referral/`,
      pembelian : `/api/v1/pembelian/`,
      priodState : '/akunting/api/kwitansi/get-paid-period/',
      tagihanPriodState : '/tagihan/api/get-paid-period-tagihan/',
      produk : `/produk/api/produk`,
      produkv1: `/produk/api/v1/produk/`,
      insertSaldoAwalv1: `/produk/api/v1/insert-saldo-awal/`,
      produkLog : `/produk/api/v1/produk-log/`,
      produkWithSn : `/produk/api/v1/produk-with-sn/`,      
      satuan : `/produk/api/satuan`,
      settingInvoice : `/master/api/setting-invoice/`,
      teknisPerangkat : `/kontak/api/v1/teknis-perangkat/`,
      teknisPerangkatTemp : `/api/v1/teknis-perangkat-temp/`,
      userv1 : `/auth/api/v1/user/`,
      vlan : `/master/api/vlan/`,
      vport : `/master/api/vport/`,      
    }
};
  