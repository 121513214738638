import { useState } from 'react';

export function  useGlobalFuntction(){
    const [isLoading, setIsLoading] = useState(false);
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : '',
        color : 'danger'
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content : 'list',
        text : '',
        title : '',
        id : 0,
        help_text_1 : '',
    })

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false, text : ''}))
    }

    let myDate = new Date();
    let lastDate = new Date();

    const [pageValue, setPageValue] = useState({
		page : 1,
		search : '',
		offset : 0,
		obj : [],
        cabang_id : '',
        cabang_obj : [],
        rekanan_id : '',
        rekanan_obj : [],
        lastPage : 1,
        bulanObj : [
            {value : '', label : '-- Semua --'},
            {value : 1, label : 'Januari'},
            {value : 2, label : 'Februari'},
            {value : 3, label : 'Maret'},
            {value : 4, label : 'April'},
            {value : 5, label : 'Mei'},
            {value : 6, label : 'Juni'},
            {value : 7, label : 'Juli'},
            {value : 8, label : 'Agustus'},
            {value : 9, label : 'September'},
            {value : 10, label : 'Oktober'},
            {value : 11, label : 'November'},
            {value : 12, label : 'Desember'},
        ],
        bulan : '',
        tahun : '',
        tahunObj : [],
        paidState : '',
        paidStateObj : [
            {value : '', label : '-- Semua --'},
            {value : 1, label : 'Unpaid'},
            {value : 2, label : 'Progress'},
            {value : 3, label : 'Paid'},
            {value : 4, label : 'Overdue'},
        ],
        startDate: myDate.setDate(1),
        endDate: lastDate,
        statusBerlangganan : '',
        statusBerlanggananObj : [
            {value : '', label : 'Semua'},
			{value : '1', label : 'Berbayar'},
			{value : '2', label : 'Free'},
        ],
        lokasi_obj : [],
        lokasi_id : '',
        billingStatusObj : [
            { value : '', label : '-- Semua --'},
            { value : 1, label : 'Daftar'},
            { value : 2, label : 'Proses'},
            { value : 3, label : 'Proses Aktivasi'},
            { value : 4, label : 'Aktif'},
            { value : 5, label : 'Isolir'},
            { value : 6, label : 'Pengajuan Putus'},
            { value : 7, label : 'Proses Putus Berlangganan'},
            { value : 8, label : 'Putus Kontrak'},
            { value : 9, label : 'Uncover'},
            { value : 10,label :  'Cancel Berlangganan'},
        ],
        billingStatus : '',
        statusOdpObj : [
            {value : '', label : '-- Semua --'},
            {value : 'yes', label : 'Memiliki ODP'},
            {value : 'no', label : 'Tidak Memiliki ODP'},
        ],
        statusOdp : ''
	});

    const handleCloseModal = () => {
        setModalValue({
            show: false,
            tipe: '',
            content : 'list',
            text : '',
            title : '',
        })
    }

    


    return {
        alertValue, setAlertValue, handleCloseAlert, modalValue, setModalValue, isLoading, setIsLoading,
        pageValue, setPageValue, handleCloseModal
    }
}